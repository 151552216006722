import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      date: "",
      author: "",
      url: "",
      content: "",
    }
  }

  handleFormSubmit(event) {
    console.log(this.state)
    let myNewPost = {
        post: `
    ---
    title: ${this.state.title}
    date: ${this.state.date}
    author: ${this.state.author}
    url: ${this.state.url}
    ---
    ${this.state.content}
    `
    }
    console.log(myNewPost)
  }

  render() {
    return (
      <Layout>
        <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        </Helmet>
        <div className="contact-form-container">
          <form className="contact-form" method="post" action="#">
            <h1>New Blog Post</h1>
            <input type="text" name="_gotcha" style={{ display: "none" }} />
            <fieldset>
              <label>
                Title*
                <input
                  placeholder="Title"
                  id="title"
                  name="title"
                  type="text"
                  tabIndex="1"
                  minLength="4"
                  required
                  value={this.state.title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Date*
                <input
                  placeholder="Date"
                  id="date"
                  name="date"
                  type="date"
                  tabIndex="2"
                  required
                  value={this.state.date}
                  onChange={e => this.setState({ date: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Author
                <input
                  placeholder="Author"
                  id="author"
                  name="author"
                  type="text"
                  tabIndex="3"
                  value={this.state.author}
                  onChange={e => this.setState({ author: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Image URL*
                <input
                  placeholder="Relative path of image"
                  id="url"
                  name="url"
                  type="url"
                  tabIndex="4"
                  pattern="./images/*"
                  required
                  value={this.state.url}
                  onChange={e => this.setState({ url: e.target.value })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label>
                Content*
                <textarea
                  placeholder="Type your Message Here...."
                  id="content"
                  name="content"
                  tabIndex="5"
                  required
                  value={this.state.content}
                  onChange={e => this.setState({ content: e.target.value })}
                ></textarea>
              </label>
              <h6 style={{ textAlign: "right", margin: 0 }}>*Required</h6>
            </fieldset>
            <fieldset><div className="g-recaptcha" data-sitekey="6LcqBlAUAAAAACDmNf7nrAtYTcTI0JSmWvTdxNRf" style={{margin:0, display:"flex", justifyContent:"center"}}></div></fieldset>
            <fieldset>
              <button
                name="submit"
                type="submit"
                id="contact-submit"
                data-submit="...Sending"
                onClick={e => this.handleFormSubmit(e)}
              >
                Submit
              </button>
            </fieldset>
            <div>
              {this.state.mailSent && (
                <div>Thank you. Your message was submitted.</div>
              )}
            </div>
          </form>
        </div>
      </Layout>
    )
  }
}
